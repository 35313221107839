.logo{
  padding-left: 4vh;
}

.carousel-inner img {
  margin: auto;
}

.App {
  
}

.carousel-hero {
  /* min-height: 60vh; */
}

.btn-link {
  background-color: #cde85d !important;
  color: hotpink !important;
}

.hero-img {
  max-height: 90vh;
}

.social-link {
  min-width: 32px;
  min-height: 32px;
}

.App-Nav {
  background-color: #cde85d;
  font-family: 'IndieFlower', cursive;
  font-weight: 400;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23CDE85D' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23CDE85D' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.navbar-nav .nav-link {
  /* margin-right: 25px; */
  margin-left: 25px;
}

/* .hero-carousel {
  height: 20vh;
} */

/* .hero-tola {
  padding-top: 12vh;
  height: 32vmax;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
} */

@font-face {
  font-family: "IndieFlower";
  src: url("./fonts/IndieFlower-Regular.ttf");
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "IndieFlower";
  font-weight: 400;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
